.mubadala {
    padding-top: 69px;
    // padding: 5px 5%;

    .head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #00638e;
        color: #fff;
        padding: 15px;
        height: 110px;

        h2 {
            margin: 0;
            font-size: 21px !important;
            margin-top: 5px;
            margin-bottom: 2px;
        }

        h6 {
            margin: 0;
            font-size: 14px !important;
            font-weight: 400;
            margin-bottom: 3px;
        }

        p {
            margin: 0;
            font-size: 12px !important;
            font-weight: 300;
            margin-bottom: 5px;
        }
    }



    .mar_top {

        padding-top: 40px;
    }

    .mar_top_ {

        padding-top: 15px;
    }

    .images_box {
        display: flex;
        align-items: start;
        justify-content: center;

        .m_skip {
            img {
                width: 31px;
                margin-top: 60%;
            }
        }

        p {
            font-size: 8px;
            text-align: center;

            a,
            span {
                color: #00638e;
            }
        }

        .m_image {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 28%;


        }
    }

    .contact__box {
        background-color: #cfe1e9;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 25px;
        padding-bottom: 40px;

        .contact_sub_box {
            width: 48%;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;
            // padding: 10px 0;


            .border__ {
                width: 0.05em;
                height: 74%;
                position: absolute;
                background: rgba(0, 99, 142, 0.6117647059);
                right: -6px;
                top: 13%;
            }

            .border__b {
                height: 0.05em;
                width: 74%;
                position: absolute;
                background: rgba(0, 99, 142, 0.6117647059);
                right: 13%;
                bottom: -8px;
            }

            img {
                height: 60px;
            }

            h4 {
                color: #389ccb;
                font-size: 11px;
                text-align: center;
                margin: 5px 0;
                height: 27px;
                margin-top: 15px;
            }

            p {
                font-size: 10px;
                font-weight: 500;
                text-align: center;
                height: 60px;
            }

            h6,
            a {
                color: #389ccb;
                font-size: 11px;
                text-align: center;
            }

            a {
                font-size: 10px;
            }

            h6 {
                margin-bottom: 5px;
            }
        }
    }
}