.time-picker-container {
  display: flex;
  align-items: center;
}

.booking-time {
  margin-right: 10px;
}

.time-picker-container button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}
