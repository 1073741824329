.food {
  .section-populer p {
    background-color: #252525;
  }
  .general-post-container .general-post .txt-pnl h2,
  .general-post .txt-pnl p,
  .s_title {
    color: #e7e7e7 !important;
  }
}
