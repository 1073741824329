.mubadala-page {
	height: 100vh; /* Ensure it takes full height */
	width: 100vw; /* Ensure full width */
	overflow: hidden; /* Prevent scrolling on this page */
}
.mubadala-container {
	background-color: #f8f9fa;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: content;
	margin-top: 4rem;
	height: 100vh;
	overflow: hidden;
	position: relative;
}
.mubadala-content {
	background-color: #ffffff;
	padding: 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
	max-width: 800px;
	width: 100%;
	margin: 0; /* Remove extra space */
}

.mubadala-content h2 {
	color: #343a40;
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 1rem;
	text-align: center;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	/* border-bottom: #007bff 2px solid; */
}
.mubadala-steps {
	padding-left: 1.5rem;
	font-size: 1.125rem;
}

.mubadala-steps li {
	margin-bottom: 1rem;
}

.mubadala-steps a {
	color: #007bff;
	text-decoration: none;
}

.mubadala-steps a:hover {
	text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
	.mubadala-container {
		padding: 0.5rem;
	}

	.mubadala-content {
		padding: 0.5rem;
	}
	.mubadala-content h2 {
		/* font-size: 1.2rem; */
		font-size: 1.2rem !important;
	}
	.mubadala-steps {
		font-size: 0.8rem;
		font-weight: 500;
		padding-left: 1rem;
	}
}
