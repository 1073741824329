@font-face {
  font-family: "FreightSans";
  src: url("../../fonts/FreightSans\ Pro\ Medium.ttf");
}

// .food.book-table p {
//   color: #e7e7e7 !important;
// }


.box_slider {
  .image_carousel {
    width: 50%;
    margin: 0 2%;
  }
}

.food {
  background-color: #000;
  color: #e7e7e7 !important;
  width: 100%;
  min-height: 100vh;
  // padding-bottom: 70px;
  padding-bottom: 70px;

  font-family: "FreightSans" !important;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a,
  li {
    font-family: "FreightSans" !important;
  }

  //   width: 95%;
  //   margin: auto;
  .select_preference {
    background-color: #ffd28d !important;
    color: #000 !important;
  }

  .box_food {
    width: 90%;
    margin: auto;

    a {
      position: relative;
      width: 100%;
      margin-bottom: 16px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 30px;
      position: relative;
      aspect-ratio: 16 / 9;
    }

    .booking_img {
      // height: 250px !important;
    }

    .select_preference {
      background-color: #ffd28d !important;
      color: #000 !important;
    }



    .overlay {
      position: absolute !important;
      top: 0;
      width: 100%;
      height: 100% !important;
      left: 0;
      filter: drop-shadow(2px 4px 6px black);
    }

    .sub_title {
      position: absolute;
      bottom: 15px;
      left: 20px;
      color: #e7e7e7 !important;
      font-size: 24px;
      letter-spacing: 2px;
      font-weight: 400;
    }
  }


  .orders .box,
  .order-accordian .accordion.v2 {
    border: 1px solid #ffd28d;
  }


  .orders a.view {
    background-color: #ffd28d !important;
    color: #000 !important;
  }

  .cls-1time,
  .orders .middel,
  .cls-2order2 {
    fill: #ffd28d;
  }

  .cls-111 {
    fill: #ffd28d;
  }

  .people_count .dropdown button {
    color: #e7e7e7 !important;
  }

  .people_count .dropdown.active button {
    background-color: #ffd28d !important;
    color: #000 !important;
  }

  .housekeeping-container .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background-color: #2c2828 !important;
    color: #ffd28d !important;
  }

  // .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  //   color: #ffd28d !important;
  // }

  .laundary-add-btn i {
    background-color: transparent !important;
  }

  .laundry_page .react-tel-input .form-control {
    border-color: #ffd28d !important;
  }

  .form-control::placeholder {
    color: #e7e7e7 !important;
  }

  .people_count ul li,
  .duration {
    background-color: #1f1c1c !important;
  }



  .p-accordion .p-accordion-header .p-accordion-header-link {
    background-color: #1f1c1c !important;
    border: none !important;
  }

  .text_feed {
    color: #e7e7e7 !important;
  }


  .current .number {
    border-color: #ffd28d !important;
    color: #ffd28d !important;
  }

  .number {
    color: #e7e7e7;
  }

  .main-home {
    height: calc(100vh - 50px);
  }

  .image_services .service_img {
    height: auto !important;
    min-height: 250px;
  }

  .time_res .time_box .btn,
  .selected.current .number,
  .people_count ul li.active {
    background-color: #ffd28d !important;
    color: #000 !important;
    ;
  }

  .add-cart-post.new.spa.gray.laun-pay .add-cart-post-inner .img-pnl {
    background-color: #e7e7e7;
  }

  .checkout_loader {
    margin-top: -30px;
    margin-bottom: 10px;

    .loader {
      width: 40px;
      height: 40px;
      border: 5px solid #ffd28d85;
      border-right-color: rgb(255 210 141);
    }
  }

  .loader {
    width: 40px;
    height: 40px;
    border: 5px solid #ffd28d85;
    border-right-color: rgb(255 210 141);
  }

  .text_food {
    color: #e7e7e7;
    position: absolute;
    bottom: 5px;
    left: 16px;
    text-align: left;

    h5 {
      margin: 0;
      font-weight: 400;
      letter-spacing: 2px;
      margin-bottom: 5px;
    }

    p {
      margin: 5px 0;
      letter-spacing: 2px;
      margin-top: -5px;
    }
  }

  .Search-bar {
    input {
      background-color: transparent !important;
      border-color: #ffd28d;
      color: #ffd28d !important;
    }

    i {
      color: #ffd28d !important;
    }
  }

  .display-list li a,
  .animated-background {
    background: linear-gradient(to right, #2c2828 8%, #1f1c1c 18%, #262222 33%);
  }

  .fixedSearch {
    top: 0 !important;
    background: #000 !important;
  }

  .reg-btn,
  .add-cart-post.new .add-cart-post-inner .txt-pnl .flex-div .add-button,
  .inline-list.search li.active button {
    background-color: #ffd28d !important;
    color: #000 !important;
    background-image: none;

    i {
      background-color: transparent !important;
    }
  }

  .inline-list.search li button {
    border-color: #ffd28d !important;
    background-color: transparent !important;
    color: #ffd28d !important;
  }

  .add-cart-post .add-cart-post-inner {
    background-color: #000;
    color: #e7e7e7 !important;

    img {
      border-radius: 10px !important;
    }

    .txt-pnl h3 {
      color: #ffd28d !important;
    }

    .txt-pnl p,
    .txt-pnl h2 {
      color: #e7e7e7 !important;
    }
  }

  .your-order-list {

    p,
    span {
      color: #e7e7e7 !important;
    }

    svg {
      fill: #e7e7e7 !important;
    }
  }

  .accordion .accordion-item .accordion-collapse .accordion-body {
    background-color: #252525;
    color: #e7e7e7 !important;
    border: none;

    .extra,
    h6,
    p {
      color: #e7e7e7 !important;
    }
  }

  .accordion .accordion-item .accordion-header button {
    border: #252525;

    b {
      color: #e7e7e7 !important;
    }

    svg {
      fill: #e7e7e7;
    }

    svg #Line_41,
    svg #Line_40 {
      stroke: #e7e7e7 !important;
    }
  }

  .count-order-amount {
    background-color: transparent !important;
    border-color: #ffd28d !important;

    span,
    button {
      color: #ffd28d !important;
    }
  }

  .search_page .display-list.v2.three-items li a p {
    color: #e7e7e7 !important;
  }

  .add-cart-post .add-cart-post-inner .txt-pnl p {
    margin-bottom: -4px !important;
  }

  .bc-btns {
    display: flex;
    justify-content: space-between;

    a {
      background-color: #ffd28d;
      color: #000;
      /* margin: 5px auto; */
      margin: 0;
      padding: 5px 12px;
      text-align: center;
      font-size: 17px;
      border-radius: 10px;
      width: 48%;
      margin-top: 10px;
    }
  }

  p.bk-title {
    color: #ffd28d !important;
  }

  .car_price,
  .book-table p {
    color: #e7e7e7 !important;
  }

  .quantity {
    span {
      border-color: #ffd28d !important;

      i {
        color: #ffd28d !important;
      }
    }
  }

  .res_info {
    padding: 0 16px;
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;

    .reg-btn {
      padding: 5px !important;
      font-weight: normal;
      margin-bottom: 0px;
      font-size: 13px;
      min-width: 170px;
      border-color: #ffd28d;
    }
  }

  .res_name {
    h5 {
      margin: 0;
      font-size: 17px;
    }

    p {
      margin: 0;
    }
  }

  .text-black {
    color: #ffd28d !important;
  }

  .viewcart .view-cart-header {
    background-color: #252525 !important;
    border: none;
  }

  .viewcart .view-cart-body {
    background-color: #000;

    h3 {
      color: #ffd28d !important;
    }
  }

  .viewcart .view-cart-header p {
    color: #ffd28d !important;
  }

  .search_categories_supermarket {
    i {
      color: #ffd28d !important;
    }
  }

  .supermarket_name {
    color: #e7e7e7 !important;

    i {
      color: #e7e7e7 !important;
    }
  }

  .subermarket_categories_slider li.active p {
    color: #e7e7e7 !important;
  }

  .add-cart-post.new .add-cart-post-inner .txt-pnl .flex-div .add-button {
    border-color: #ffd28d !important;
  }

  .spinner-border {
    border: 0.25em solid #ffd28d;
    border-right-color: transparent;
  }

  .scan p {
    color: #ffd28d;
  }

  .scan button {
    color: #ffd28d;
    background-color: #252525 !important;
  }

  .display-list.v2.car-list li button p {
    color: #ffd28d !important;
  }

  .sh_btn.sh_full {
    background-color: #252525 !important;
    border-color: #252525 !important;
    color: #e7e7e7 !important;
  }

  .slot_text {
    color: #ffd28d;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .car-drop-flex .car-drop .dropdown-toggle {
    border-color: #ffd28d !important;
    color: #ffd28d !important;
  }

  .box_ {
    background-color: #000 !important;

    .title_ {
      color: #ffd28d !important;
    }
  }

  .your-order-list li:not(:last-child),
  .your-order-list {
    border-color: #ffd28d !important;
  }

  svg #Path_313,
  svg #Icon_awesome-receipt,
  svg #Path_307,
  svg #Path_314 {
    fill: #e7e7e7 !important;
  }

  input[type="checkbox"],
  input[type="radio"] {
    accent-color: #ffd28d !important;
  }

  .orders .flex p,
  .orders .flex .top-title span,
  .orders .datetime span {
    color: #ffd28d !important;
  }

  .about-us .info {
    background-color: #252525;
  }

  .about-us .cls-1lo,
  .about-us .cls-1ex,
  .about-us .cls-1info {
    fill: #ffd28d !important;
  }

  .about-us .desc,
  .about-us table td,
  .about-us table th {
    color: #e7e7e7;
  }

  .about-us .info p {
    color: #ffd28d !important;
  }

  .back-btn:hover,
  .back-btn {
    display: flex;
    border: 1px solid;
    margin: 0;
    padding: 0 4px;
    top: 50%;
    position: absolute;
    left: 3%;
    width: 65px;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    color: #ffd28d !important;
    border-color: #ffd28d !important;
    transform: translateY(-50%);
    background-color: #000 !important;

    svg {
      fill: #ffd28d;
      height: 100%;
    }

    p {
      margin: 0;
      margin-left: 5px;
      padding-inline: initial;
    }
  }

  .mcdonald.display-list li a p,
  .mcdonald.res_sp.display-list li a p {
    color: #ffd28d !important;
  }

  .meal_card {
    background-color: #000 !important;
  }

  .open_card {
    background-color: #ffd28d;
    color: #000;
  }

  .extras-heading {
    background-color: #252525;

    p {
      color: #ffd28d;
    }
  }

  .payemtn-type-list li.active a {
    background-color: #252525 !important;
    border-color: #252525 !important;
  }

  .maintenances .display-list.v2.three-items li a {
    background-color: #252525 !important;
  }

  .iframe_layar {
    top: 0 !important;
    height: 100% !important;
  }

  .pharmacy_new .pharmacy_new_box .left_list li a {
    box-shadow: -1px 1px 5px #141212;
  }

  .pharmacy_new .pharmacy_new_box .left_list li.active_item a {
    background: #ffd28d;
    color: #000;
    box-shadow: -1px 1px 5px #141212;
    border-color: #ffd28d;
  }

  .ph_des {
    padding: 0 4%;
    box-shadow: 3px 2px 3px #181818;
    margin-bottom: 19px;
    padding-bottom: 4px;
  }

  .pharmacy_new .p-accordion-toggle-icon {
    font-family: "FontAwesome" !important;
  }

  .pharmacy_new .pharmacy_new_box .left_list li.active_item a p {
    color: #000 !important;
  }

  .pharmacy_new .pharmacy_new_box .left_list li a {
    border-color: #ffd28d;

    p {
      color: #ffd28d !important;
    }



  }

  .pharmacy_new .pharmacy_new_box .auc .p-accordion .p-accordion-header .p-accordion-header-link {
    background-color: transparent !important;
    color: #ffd28d !important;
  }


  .back-btn p {
    color: #ffd28d !important;
    font-weight: normal !important;
    font-size: 14px;
  }

}

.food-modal .modal-content {
  background-color: #0d0d0d !important;

  .count-order-amount button,
  .feed_done {
    color: #ffd28d;
  }

  .close-pop svg {
    fill: #e7e7e7 !important;
  }

  .title {
    color: #ffd28d !important;
  }

  .car-input-container input,
  .react-tel-input .flag-dropdown,
  .body-container .input-pnl input,
  .body-container .input-pnl textarea {
    border-color: #ffd28d !important;
    color: #e7e7e7 !important;
  }

  .maintenance h4 {
    color: #e7e7e7 !important;
  }

  input::placeholder,
  textarea::placeholder,
  input {
    color: #e7e7e7 !important;
  }

  .feed_btns button,
  .reg-btn {
    background-color: #ffd28d !important;
    color: #000 !important;
    background-image: none;
  }

  .modal-text-container.sub-menu,
  .modal-text-container.sub-menu .price-container p {
    color: #e7e7e7 !important;
  }

  .count-order-amount {
    border-color: #ffd28d !important;
    background-color: transparent !important;
    color: #ffd28d !important;
  }

  .modal-text-container.sub-menu .price-container .count-order-amount button,
  .modal-text-container.sub-menu .price-container p {
    color: #ffd28d !important;
  }

  .close-btn .close-btn-wrap {
    background-color: #ffd28d !important;
  }

  .extras-heading {
    background-color: #252525;

    .text {
      color: #ffd28d !important;
    }
  }

  .reg-btn {
    border-color: #ffd28d !important;
  }

  .full-heading {
    background-color: #252525 !important;
    margin-bottom: 10px !important;
    color: #e7e7e7;

    h3 {
      color: #ffd28d !important;
    }
  }

  .bk-date p,
  .bk-time p {
    color: #e7e7e7 !important;
  }

  input[type="date"].form-control {
    color: #e7e7e7 !important;
  }

  .check-list-container .check-list li {
    background-color: #0d0d0d !important;
    color: #e7e7e7;
  }

  input[type="checkbox"] {
    accent-color: #ffd28d !important;
    accent-color: #ffd28d !important;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #ffd28d;
    /* Default border color */
    border-radius: 50%;
    background-color: #fff;
    /* Default background color */
    cursor: pointer;
    position: relative;
  }

  input[type="radio"]:checked {
    background-color: #ffd28d !important;
    accent-color: #ffd28d;
    /* Background color when selected */
    border: 2px solid #ffd28d;
    /* Border color when selected */
  }

  #start-camera {
    background-color: #ffd28d !important;
  }
}

.arabic {
  direction: rtl;

  .box_food .sub_title {
    right: 20px;
    left: initial;
  }

  .text_food {
    right: 16px;
    left: initial;
    text-align: right;
  }
}

.nav_theme {
  background: #0d0d0d !important;
  position: relative;

  .dropdown-menu {
    background-color: #252525 !important;

    a {
      color: #e7e7e7 !important;
    }
  }

  .dropdown .dropdown-menu a:hover {
    background-color: #0d0d0d !important;

    span {
      color: #ffd28d;
    }
  }

  .back-btn-container .back-btn p {
    color: #ffd28d !important;
  }

  .cls-2order2,
  .back-btn-container .back-btn svg {
    fill: #ffd28d !important;
  }

  .dropdown-toggle::after,
  .dropdown {
    border-color: #ffd28d !important;
  }

  .nav-link span,
  .dropdown .nav-link {
    color: #ffd28d !important;
  }

  .cart-count {
    background-color: #ffd28d !important;

    p {
      color: #000;
    }
  }

  svg {

    #Path_292,
    #Path_293,
    #Path_294 {
      fill: #ffd28d !important;
    }
  }
}

@media(max-width:450px) {
  .food .box_food img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 30px;
    position: relative;
  }
}

.fa-plus:before,
.fa-minus:before,
i {
  font-family: 'FontAwesome' !important;
  font-style: normal;
}

.h_theme {
  height: calc(100vh - 100px);
}