.food .animated-background {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #151212 8%, #2e2929 18%, #191919 33%);
}

.food .general-post-container {
  border-bottom: 1px solid #383838;
}
