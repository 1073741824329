.title__ {
  text-align: center;
  padding: 8px;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 2px;
  cursor: pointer;
  width: 60%;
  // background: red;
  margin-left: 20px;
}

.nav__title {
  position: relative;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  .cart-container {
    position: absolute;
    right: 0;

    svg #Path_292,
    svg #Path_293,
    svg #Path_294 {
      fill: #ffd28d !important;
    }

    .cart-count {
      position: absolute;
      top: 2px;
      background-color: #ffd28d;
      right: 5px;

      .count {

        color: #000;
      }
    }
  }
}

.nav__title.fix {
  background-color: #000;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
}