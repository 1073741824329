.new_page {

  .order_page,
  .banner .carousel .slide video,
  .banner .carousel .slide img {
    object-fit: cover;
    height: 100% !important;
    border-radius: 25px;
    margin-bottom: 5px;
    position: relative;
    padding-bottom: 7px;
  }

  .banner-services {
    padding: 0 10px;
  }

  .banner-services .carousel .slide img {
    // height: auto !important;
  }

  .bot_rad .carousel .slide video,
  .bot_rad .carousel .slide img {
    border-radius: 0;
    height: 255px !important;
  }

  .banner .carousel .slide .ove {
    position: absolute;
    width: 100%;
    height: 100% !important;
    left: 0;
    bottom: 0;
  }

  .carousel .slide {
    padding: 0px 3px !important;
    border-radius: 25px !important;
    overflow: hidden !important;
  }

  .carousel-item-custom {
    // padding: 0px 3px !important;
    border-radius: 25px !important;
    overflow: hidden !important;
  }

}

.food {

  .banner .carousel .slide video,
  .banner .carousel .slide img {
    // object-fit: cover;
    height: auto !important;
    border-radius: 25px;
    // margin-bottom: 5px;
    position: relative;
    padding-bottom: 7px;
    aspect-ratio: 16/9
  }

  .order_page .banner .carousel .slide video,
  .order_page .banner .carousel .slide img {
    object-fit: cover;
    height: 100% !important;
    border-radius: 25px;
    margin-bottom: 5px;
    position: relative;
    padding-bottom: 7px;
    aspect-ratio: inherit;
  }

  .image_services {


    img {
      // object-fit: cover;
      height: auto !important;
      border-radius: 25px;
      // margin-bottom: 5px;
      position: relative;
      padding-bottom: 7px;
      aspect-ratio: inherit !important
    }

    .overlay {
      height: 100% !important;
    }
  }

  .carousel .slide img {
    height: auto !important;
    aspect-ratio: 16/9
  }

  .banner-services {
    padding: 0 10px;
  }

  .banner-services .carousel .slide img {
    // height: auto !important;
  }



  .bot_rad .carousel .slide video,
  .bot_rad .carousel .slide img {
    border-radius: 0;
    height: 100% !important;
  }

  .banner .carousel .slide .ove {
    position: absolute;
    width: 100%;
    height: 100% !important;
    left: 0;
    bottom: -1px;
    object-fit: cover;
  }

  .carousel .slide {
    padding: 0px 3px !important;
    border-radius: 25px !important;
    overflow: hidden !important;
  }

  .carousel-item-custom {
    // padding: 0px 3px !important;
    // border-radius: 25px !important;
    overflow: hidden !important;
    position: relative;
  }

  .bot_rad {

    .banner .carousel .slide video,
    .banner .carousel .slide img,
    .carousel .slide {
      border-radius: 0 !important;
    }
  }

}