.food {
  .view-cart-body {
    h3 {
      color: #e7e7e7 !important;
    }
  }

  .p-accordion .p-accordion-content {
    background-color: black;
    border-color: #333333 !important;
    color: #e7e7e7 !important;
  }
  .add-cart-post.new.spa.gray.laun-pay .add-cart-post-inner {
    background-color: #252525 !important;
    color: #e7e7e7 !important;
  }
  h3,
  .laundry-order-container .order-placed,
  .laundray_check label {
    color: #e7e7e7 !important;
  }
  .book-table p {
    color: #e7e7e7 !important;
  }
  .quantity input {
    background-color: transparent !important;
    color: #ffd28d !important;
    border-color: #ffd28d;
  }
  #click-photo,
  .quantity button {
    color: #ffd28d !important;
    border-color: #ffd28d;
    i {
      color: #ffd28d !important;
    }
  }
  #click-photo {
    background-color: #252525 !important;
  }
  #start-camera {
    background: #ffd28d !important;
    color: #0d0d0d;
  }
  .note .time-select {
    background-color: transparent !important;
    color: #ffd28d !important;
    border-color: #ffd28d;
  }
  .housekeeping-container p.service {
    color: #e7e7e7 !important;
  }
  .housekeeping-container .p-accordion-tab:hover,
  .housekeeping-container
    .p-accordion
    .p-accordion-header
    .p-accordion-header-link:hover,
  .housekeeping-container .p-accordion-header:hover {
    border-radius: 10px;
    background-color: #252525 !important;
    color: #ffd28d !important;
  }
  .housekeeping-container
    .p-accordion
    .p-accordion-header
    .p-accordion-header-link {
    background-color: #ffd28d !important;
  }
  tr.main {
    background-color: #ffd28d !important;
    color: #0d0d0d;
  }
  .hk-order-container .text-pnl h2 {
    color: #ffd28d !important;
  }
  .booking-order-detail {
    background-color: #252525 !important;
    .txt-pnl p {
      color: #e7e7e7 !important;
    }
    h3 {
      color: #ffd28d !important;
    }
  }
  .add-cart-post.new.spa.gray .add-cart-post-inner {
    background-color: #252525 !important;
  }
  .spa-order-placed .order-placed {
    color: #ffd28d !important;
  }
  .salon-table table tr.main th {
    color: #0d0d0d !important;
  }
  .salon-table table tr.detailes td p:first-child,
  .salon-table table tr.detailes td {
    color: #e7e7e7 !important;
  }
  .housekeeping-container
    .p-accordion
    .p-accordion-header
    .p-accordion-header-link {
    color: #000 !important;
    border-color: #000 !important;
  }
  .housekeeping-container
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background-color: #ffd28d !important;
    color: #000 !important;
  }
}
.dark_theme {
  background-color: #252525 !important;

  .p-dropdown-items .p-dropdown-item {
    color: #e7e7e7 !important;
  }
  .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: #0d0d0d;
  }
}

.food-modal {
  z-index: 11050;
  .modal-body .add-item {
    color: #e7e7e7 !important;

    i {
      color: #e7e7e7 !important;
    }
  }
  .modal-body * {
    // color: #e7e7e7 !important;
  }
  .panel-drop,
  .f-dropdown11 > span,
  .drop-container .p-dropdown {
    background-color: #252525 !important;
    color: #c3c3c3 !important;
  }
  .f-dropdown11.open ul,
  .panel-drop {
    background-color: #252525 !important;
  }
  .react-tel-input .flag-dropdown,
  textarea.form-control,
  input,
  .dt-input.time-input {
    background-color: #252525 !important;
  }
  .img-pnl {
    h4,
    p {
      color: #ffd28d !important;
    }
  }
  .discount_percentage,
  .input-pnlm p {
    color: #e7e7e7 !important;
  }
  .f-dropdown11 > span > span,
  .drop-container .p-dropdown .p-dropdown-label,
  ul li span,
  label,
  .laundary-modal .modal-body .count-order-amount button,
  .total_coast {
    color: #ffd28d !important;
  }
}
